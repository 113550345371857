// Toggle global options
$enable-gradients: true;
$enable-shadows: true;
$enable-negative-margins: true;

//Custom Variables
$primary: #322E59;
$secondary: #1DA8A0;
$info: #8B88AF;
$light: #E5E5E5;

$utilities: (
  "width": (
    property: width,
    responsive: true,
    class: w,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
  "height": (
    property: height,
    responsive: true,
    class: h,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
);

//Bootstrap
@import "bootstrap/scss/bootstrap"; 


//Custom styles....

@media only screen and (min-width: 767px) {
      .bg-md-faint-grey {
        background: #FDFDFD !important;
    }
    .bg-md-white {
        background: #ffffff !important;
    }
    .text-md-primary {
        color: $primary !important;
    }
    .stroke-md-primary {
        stroke: $primary !important;
    }
    .progress {
        height: 0.2rem !important;
    }
}

input::placeholder {
  color: #aaa;
  font-weight: bold;
}

.logo {
 width: 150px;
}

@media only screen and (max-width: 767px) {
  .logo {
    width: 120px;
  }
  .logo-small {
    width: 30px;
  }
  .sidebar {
    transform: translateX(-100vw);
    transition: .25s;
    position: fixed !important;
    z-index: 99;
  }
  .sidebar.active {
    transform: translateX(0vw);
  }

  .menu li a {
    color: white !important;
    &:hover {
      color: $secondary !important;
    }
  }
}

.uppercase-small {
  font-size: .8em;
  letter-spacing: .8px;
  font-weight: 600;
}

.shadow-sm {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
}

ul {
  padding-inline-start: 0px;
  list-style: none;
}

.quick-jump li:not(:first-child):not(:last-child):after {
  position: absolute;
  content:"";
  border-radius: 100%;
  width: 5px;
  height: 5px;
  background: $secondary;
  right: -30px;
  top: 0;
  bottom: 0;
  margin: auto;
  @media only screen and (max-width: 996px) {
    right: -10px;
    width: 2px;
    height: 2px;
  }
}

.status li:not(:last-child):after {
  position: absolute;
  content:"";
  border-radius: 100%;
  width: 5px;
  height: 5px;
  background: $primary;
  right: -20px;
  top: 0;
  bottom: 0;
  margin: auto;
  @media only screen and (max-width: 996px) {
    right: -10px;
    width: 2px;
    height: 2px;
  }
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.select-wrapper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  select {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none !important;
    box-shadow: none !important;
    background: white;
    padding-right: 40px !important;
  }
  &:after {
    content: '';
    background-image: url('/assets/images/icons/chevron-down.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    color: #000;
    pointer-events: none;
  }
}


.form-check label{
 position: relative;
 top:2px;
}
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  &:checked {
    background-color: $secondary;
    border-color: $secondary;
  }
  &:focus {
    box-shadow: none !important;
  }
}

input {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  outline: 0;
  &.border {
    &.border-light {
      &:focus {
       border: 1px solid $secondary !important;
      }
    }
  }
  &::placeholder {
    font-weight: 100;
  }
}

// Custom rounded class
.rounded {
  border-radius: 6px !important;
  &-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}